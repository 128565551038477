import {Link} from "react-router-dom";
import React from "react";
import "./sidebar.css";
import {useLocation} from "react-router-dom/cjs/react-router-dom.min";
import {Visibility, VpnKey} from "@material-ui/icons";

const Sidebar = (props) => {
  const [toggle, setToggle] = React.useState(true);
  const [userInfo, setUserInfo] = React.useState(JSON.parse(localStorage.getItem('userInfo')));
  const [isNormalUser, setIsNormalUser] = React.useState(localStorage.getItem('isNormalUser'));
  const [userRole, setuserRole] = React.useState(localStorage.getItem('userRole'));
  console.log("prospss", props);

  const toggleMenu = () => {
    setToggle(!toggle);
    props.getState(toggle);
  };

  const location = useLocation();
  return (
    <div>
      {toggle ? (
        <div
          className="nk-fmg-aside"
          data-content="files-aside"
          data-toggle-overlay="true"
          data-toggle-body="true"
          data-toggle-screen="lg"
          data-simplebar
          style={{left: "0px !important"}}
        >
          <div className="nk-fmg-aside-wrap">
            <div className="nk-fmg-aside-top">
              <ul className="nk-fmg-menu">
                {(isNormalUser != true && isNormalUser != "true") || (userRole == "admin" || userRole == "admin-member") ?
                  <Link to="/dashboard">
                    <li className={props.page === "dashboard" ? "active" : null}>
                      <a
                        className="nk-fmg-menu-item"
                        href="html/apps/shared.html"
                      >
                        <em className="icon ni ni-dashboard"></em>
                        <span className="nk-fmg-menu-text">Dashboard</span>
                      </a>
                    </li>
                  </Link>
                  : ""}
                {/* Moved all its functionality to Esign/Sent Documents Section  */}




                {(isNormalUser === true || isNormalUser === "true") || (userRole == "admin" || userRole == "admin-member") ?
                  <Link to="/eSign-documents">
                    <li className={props.page === "shared" ? "active" : null}>
                      <a
                        className="nk-fmg-menu-item"
                        href="html/apps/shared.html"
                      >
                        <svg
                         xmlns="http://www.w3.org/2000/svg"
                         style={{marginRight:'10px'}}
                         height="1.4em"
                         viewBox="0 0 576 512">
                          <path
                           fill={props.page === "shared" ? "rgb(20, 155, 227)" : "#8094ae"}
                           d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V428.7c-2.7 1.1-5.4 2-8.2 2.7l-60.1 15c-3 .7-6 1.2-9 1.4c-.9 .1-1.8 .2-2.7 .2H240c-6.1 0-11.6-3.4-14.3-8.8l-8.8-17.7c-1.7-3.4-5.1-5.5-8.8-5.5s-7.2 2.1-8.8 5.5l-8.8 17.7c-2.9 5.9-9.2 9.4-15.7 8.8s-12.1-5.1-13.9-11.3L144 381l-9.8 32.8c-6.1 20.3-24.8 34.2-46 34.2H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h8.2c7.1 0 13.3-4.6 15.3-11.4l14.9-49.5c3.4-11.3 13.8-19.1 25.6-19.1s22.2 7.8 25.6 19.1l11.6 38.6c7.4-6.2 16.8-9.7 26.8-9.7c15.9 0 30.4 9 37.5 23.2l4.4 8.8h8.9c-3.1-8.8-3.7-18.4-1.4-27.8l15-60.1c2.8-11.3 8.6-21.5 16.8-29.7L384 203.6V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM549.8 139.7c-15.6-15.6-40.9-15.6-56.6 0l-29.4 29.4 71 71 29.4-29.4c15.6-15.6 15.6-40.9 0-56.6l-14.4-14.4zM311.9 321c-4.1 4.1-7 9.2-8.4 14.9l-15 60.1c-1.4 5.5 .2 11.2 4.2 15.2s9.7 5.6 15.2 4.2l60.1-15c5.6-1.4 10.8-4.3 14.9-8.4L512.1 262.7l-71-71L311.9 321z"
                           /></svg>
                        <span className="nk-fmg-menu-text">eSignature</span>
                        {/* <span className="nk-fmg-menu-text">Sent Documents</span> */} 
                      </a>
                    </li>
                  </Link>
                  : ""}
                <Link to="/file-sharing">
                  <li className={props.page === "file-sharing" ? "active" : null}>
                    <a
                      className="nk-fmg-menu-item"
                      href="html/apps/shared.html"
                    >
                      <em className="icon ni ni-share-fill"/>
                      <span className="nk-fmg-menu-text">File Sharing</span>
                    </a>
                  </li>
                </Link>
                {(isNormalUser != true && isNormalUser != "true") || (userRole == "admin" || userRole == "admin-member") ?
                  <Link to="/document-ocr">
                    <li className={props.page === "ocr" ? "active" : null}>
                      <a
                        className="nk-fmg-menu-item"
                        href="/document-ocr"
                        stye={{paddingLeft: "0.5rem"}}
                      >
                        <Visibility
                          style={{
                            fontSize: "1.25rem",
                            lineHeight: "1.25rem",
                            marginTop: "-2px",
                            width: "2rem",
                            color: "#8094ae",
                            marginRight: "0.3rem",
                            marginLeft: "-7px"
                          }}
                        />
                        <span className="nk-fmg-menu-text">Document OCR</span>
                      </a>
                    </li>
                  </Link>
                  : ""}
                   {(isNormalUser != true && isNormalUser != "true") || (userRole == "admin" || userRole == "admin-member") ?
                  <Link to="/document-hub">
                    <li
                      className={
                        location.pathname === "/document-hub" ? "active" : null
                      }
                    >
                      <a
                        className="nk-fmg-menu-item"
                        href="html/apps/file-manager.html"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" height="1.3em" style={{marginRight:'10px', marginBottom:"4px"}} viewBox="0 0 512 512"><path fill={location.pathname === "/document-hub" ? "rgb(20, 155, 227)" : "#8094ae"} d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"/></svg>
                        <span className="nk-fmg-menu-text">PDF Editor</span>
                      </a>
                    </li>
                  </Link>
                  : ""}
                  {(isNormalUser != true && isNormalUser != "true") || (userRole == "admin" || userRole == "admin-member") ?
                  <Link to="/created-documents">
                    <li className={props.page === "files" ? "active" : null}>
                      <a className="nk-fmg-menu-item" href="html/apps/files.html">
                        <em className="icon ni ni-file-docs"/>
                        <span className="nk-fmg-menu-text">
                        Created Documents
                      </span>
                      </a>
                    </li>
                  </Link>
                  : ""}
                {(isNormalUser != true && isNormalUser != "true") || (userRole == "admin" || userRole == "admin-member") ?
                  <Link to="/contact">
                    <li className={props.page === "contact" ? "active" : null}>
                      <a
                        className="nk-fmg-menu-item"
                        href="html/apps/shared.html"
                      >
                        <em className="icon ni ni-contact-fill"></em>
                        <span className="nk-fmg-menu-text">Contacts</span>
                      </a>
                    </li>
                  </Link>
                  : ""}

                {(isNormalUser != true && isNormalUser != "true") || (userRole == "admin" || userRole == "admin-member") ?
                  <Link to="/configuration">
                    <li
                      className={
                        location.pathname === "/configuration" ? "active" : null
                      }
                    >
                      <a
                        className="nk-fmg-menu-item"
                        href="/configuration"
                        stye={{paddingLeft: "0.6rem !important"}}
                      >
                        <VpnKey
                          style={{
                            fontSize: "1.25rem",
                            lineHeight: "1.25rem",
                            marginTop: "-2px",
                            width: "2rem",
                            color: "#8094ae",
                            marginRight: "0.3rem",
                          }}
                        />
                        <span className="nk-fmg-menu-text">API Portal</span>
                      </a>
                    </li>
                  </Link>
                  : ""}
                <li className="text-center my-3 p-2">
                  <a href="#" onClick={toggleMenu}>
                    <em
                      className="icon ni ni-step-back"
                      style={{fontSize: 20}}
                    ></em>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="nk-fmg-aside"
          data-content="files-aside"
          data-toggle-overlay="true"
          data-toggle-body="true"
          data-toggle-screen="lg"
          data-simplebar
          style={{left: "0px !important", width: 80}}
        >
          <div className="nk-fmg-aside-wrap">
            <div className="nk-fmg-aside-top">
              <ul className="nk-fmg-menu">
                {(isNormalUser != true && isNormalUser != "true") || (userRole == "admin" || userRole == "admin-member") ?
                  <Link to="/dashboard">
                    <li className={props.page === "dashboard" ? "active" : null}>
                      <a
                        className="nk-fmg-menu-item"
                        href="html/apps/shared.html"
                      >
                        <em className="icon ni ni-dashboard"></em>
                      </a>
                    </li>
                  </Link>
                  : ""}
                {(isNormalUser != true && isNormalUser != "true") || (userRole == "admin" || userRole == "admin-member") ?
                  <Link to="/document-hub">
                    <li
                      className={
                        location.pathname === "/document-hub" ? "active" : null
                      }
                    >
                      <a
                        className="nk-fmg-menu-item"
                        href="html/apps/file-manager.html"
                      >
                        <em className="icon ni ni-home-alt"/>
                      </a>
                    </li>
                  </Link>
                  : ""}
                {(isNormalUser != true && isNormalUser != "true") || (userRole == "admin" || userRole == "admin-member") ?
                  <Link to="/created-documents">
                    <li className={props.page === "files" ? "active" : null}>
                      <a className="nk-fmg-menu-item" href="html/apps/files.html">
                        <em className="icon ni ni-file-docs"/>
                      </a>
                    </li>
                  </Link>
                  : ""}
                {(isNormalUser != true && isNormalUser != "true") || (userRole == "admin" || userRole == "admin-member") ?
                  <Link to={"/eSign-documents"}>
                    <li className={props.page === "shared" ? "active" : null}>
                      <a
                        className="nk-fmg-menu-item"
                        href="html/apps/shared.html"
                      >
                        <em className="icon ni ni-share-alt"/>
                      </a>
                    </li>
                  </Link>
                  : ""}
                <Link to={"/file-sharing"}>
                  <li className={props.page === "file-sharing" ? "active" : null}>
                    <a
                      className="nk-fmg-menu-item"
                      href="html/apps/shared.html"
                    >
                      <em className="icon ni ni-share-fill"/>
                    </a>
                  </li>
                </Link>
                {(isNormalUser != true && isNormalUser != "true") || (userRole == "admin" || userRole == "admin-member") ?
                  <Link to="/contact">
                    <li className={props.page === "contact" ? "active" : null}>
                      <a
                        className="nk-fmg-menu-item"
                        href="html/apps/shared.html"
                      >
                        <em className="icon ni ni-contact-fill"></em>
                      </a>
                    </li>
                  </Link>
                  : ""}
                {(isNormalUser != true && isNormalUser != "true") || (userRole == "admin" || userRole == "admin-member") ?
                  <Link to="/document-ocr">
                    <li className={props.page === "ocr" ? "active" : null}>
                      <a className="nk-fmg-menu-item" href="/document-ocr">
                        <Visibility
                          style={{
                            fontSize: "1.25rem",
                            lineHeight: "1.25rem",
                            marginTop: "-2px",
                            width: "2rem",
                            color: "#8094ae",
                            marginRight: "0.3rem",
                          }}
                        />
                      </a>
                    </li>
                  </Link>
                  : ""}
                {(isNormalUser != true && isNormalUser != "true") || (userRole == "admin" || userRole == "admin-member") ?
                  <Link to="/configuration">
                    <li
                      className={
                        location.pathname === "/configuration" ? "active" : null
                      }
                    >
                      <a className="nk-fmg-menu-item" href="/configuration">
                        <VpnKey
                          style={{
                            fontSize: "1.25rem",
                            lineHeight: "1.25rem",
                            marginTop: "-2px",
                            width: "2rem",
                            color: "#8094ae",
                            marginRight: "0.3rem",
                          }}
                        />
                      </a>
                    </li>
                  </Link>
                  : ""}

                <li className="text-center my-3 p-2">
                  <a className="" href="#" onClick={toggleMenu}>
                    <em
                      className="icon ni ni-arrow-to-right"
                      style={{fontSize: 20}}
                    ></em>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
