import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  MenuItem,
  TextField,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../constants/apiconstant";
import { getHeaders } from "../../utils";
import "./AddEmailFromContacts.css";

export default function AddEmailFromContacts({
  onClose,
  onAddClick,
  contacts = [],
  loading,
  participants,
  newParticipant,
  getContacts,
}) {
  const [modalStyle] = useState(getModalStyle);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedEmailWithUserIdAndCompanyId, setSelectedEmailWithUserIdAndCompanyId] = useState([])
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = React.useState(false);
  const { innerWidth, innerHeight } = window;

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  function getModalStyle() {
    const { innerWidth, innerHeight } = window;

    let top = 50,
      left = innerWidth < 600 ? 0 : 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${left}%, -${top}%)`,
    };
  }
  const handleCheckBoxClick = (email, userId, companyId) => {
    let isFound = false;

    participants.forEach((participant) => {
      if (participant.participantEmail == email) {
        isFound = true;
      }
    });
    newParticipant.forEach((participant) => {
      if (participant.participantEmail == email) {
        isFound = true;
      }
    });
    if (isFound) return;
    if (selectedEmails.indexOf(email) > -1) {
      setSelectedEmails((prev) => prev.filter((e) => e != email));
      setSelectedEmailWithUserIdAndCompanyId(prev => prev.filter(e => e.participantEmail != email))
    } else {
      setSelectedEmails([...selectedEmails, email]);
      setSelectedEmailWithUserIdAndCompanyId([...selectedEmailWithUserIdAndCompanyId, {
        participantEmail:email, 
        userId, 
        companyId
      }])
    }
  };
  return (
    <Dialog
      style={{ borderRadius: "11px" }}
      open={true}
      onClose={onClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title" style={{ fontFamily: "Nunito" }}>
        Add Email
      </DialogTitle>
      <DialogContent style={{ minWidth: "30rem" }} dividers={true}>
        {!search && (
          <div className="card-tools mr-n1">
            <ul
              className="btn-toolbar gx-1"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <li>
                <a
                  onClick={() => setSearch(true)}
                  className="btn btn-icon search-toggle toggle-search"
                  data-target="search"
                >
                  <em className="icon ni ni-search"></em>
                </a>
              </li>
            </ul>
          </div>
        )}
        {search && (
          <div className="search-content">
            <a
              className="search-back btn btn-icon toggle-search"
              onClick={() => {
                setSearch(false);
                getContacts();
                setSearchText("");
              }}
              data-target="search"
            >
              <em className="icon ni ni-arrow-left"></em>
            </a>
            <input
              type="text"
              value={searchText}
              onChange={(e) => {
                getContacts(e.target.value);
                setSearchText(e.target.value);
              }}
              className="form-control border-transparent form-focus-none"
              placeholder="Search by user or email"
            />
          </div>
        )}
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "2rem",
            }}
          >
            <CircularProgress style={{color:"rgb(20, 155, 227)"}} size={30} />
          </div>
        ) : (
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {contacts.map((c) => {
                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      style={{
                        color: "#f50057",
                        padding: "9px",
                        fontSize: "1.5rem",
                        textAlign: "center",
                      }}
                      onClick={() => handleCheckBoxClick(c?.email, c?.id, c?.company_id)}
                      type="checkbox"
                    ></input>
                    <span
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: 400,
                        fontSize: "1rem",
                        letterSpacing: "0.00938em",
                        lineHeight: 1.5,
                        padding: "9px",
                        color: "black",
                      }}
                    >
                      {c?.email}
                    </span>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button style={{ fontFamily: "Nunito" }} onClick={onClose}>
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ fontFamily: "Nunito" }}
          onClick={() => onAddClick(selectedEmailWithUserIdAndCompanyId)}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
