import React, { useEffect, useRef, useState } from "react";

import "../../assets/css/dashlite.css";
import "../../assets/css/theme.css";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { useHistory } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Grid, Container, Modal, TextField, Fab } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import CreateFolder from "./CreateFolder";
import Swal from "sweetalert2";

import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import { html } from "../Html";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import EsigFileUpload from "../../components/EsigFileUpload";
import FileButton from "../../components/FileButton";
import { FolderButton } from "./FolderButton";
import "../homepage.css";
import PrepareSend from "./PrepareSend";
import axios from "axios";
import { getHeaders } from "../../utils";
import { BASE_URL } from "../../constants/apiconstant";
import { FolderList } from "./FolderList";
import { useSnackbar } from "notistack";
import MoveInFolder from "./MoveFolderModal";
import Paginator from "../../components/Pagination";
import { removeEsignDOcuments } from "../../actions/assetsAction";
import RenameFile from "./RenameFile";
import DeviceAndLocationModal from "./DeviceAndLocationModal";
import folderCreateModalSwal from "./createFolderSwal";
import StorageType from "../../components/StorageType";

const Shared = () => {
  const [showCreateFolderModal, setShowClearFolderModal] = useState(false);
  const dispatch = useDispatch();
  const [modalStyle] = useState(getModalStyle);
  const { innerWidth, innerHeight } = window;
  const history = useHistory();
  function getModalStyle() {
    const { innerWidth, innerHeight } = window;

    let top = 50,
      left = innerWidth < 600 ? 0 : 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${left}%, -${top}%)`,
      borderRadius: "11px",
    };
  }

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: innerWidth > 600 ? 500 : "100%",

      backgroundColor: theme.palette.background.paper,
      // border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      maxHeight: "calc(100vh - 100px)",
      overflow: "auto !important",
      top: "50%",
      fontFamily: "Nunito, sans-serif !important",
    },
    multilineColor: {
      color: "white",
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    flex: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "1rem",
      flex: 1,
    },
    rooot: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    fieldroot: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
  }));
  const classes = useStyles();

  const [pendingDocuments, setPendingDocuments] = useState({
    activePage: 1,
    pages: 1,
    data: [],
  });
  const [completedDocuments, setCompletedDocuments] = useState({
    activePage: 1,
    pages: 1,
    data: [],
  });
  const [uncompletedDocuments, setUnCompletedDocuments] = useState({
    activePage: 1,
    pages: 1,
    data: [],
  });
  const [folders, setFolders] = useState({ activePage: 1, pages: 1, data: [] });
  const [loading, setLoading] = useState({
    completed: true,
    pending: true,
    uncompleted: true,
    folder: true,
  });
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [fileToMoveId, setFileToMoveId] = useState([]);
  const [showMoveInFolderModal, setShowMoveInFolderModal] = useState(false);
  const [bodyWidth, setBodyWidth] = React.useState(false);
  const [folderClicked, setFolderClicked] = useState(null);
  const [folderFilesGetLoading, setFolderFilesGetLoading] = useState(false);
  const [folderFiles, setFolderFiles] = useState([]);
  const [showMoveButton, setShowMoveButton] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [originamFilename, setOriginalFilname] = useState("");
  const [showInfoModel, setShowInfoModel] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const state = useSelector((state) => state);
  const getAssets = useSelector((state) => state.getAssets);
  const { error, file, folder, ocr } = getAssets;

  const sidebarState = (state) => {
    console.log(state);
    setBodyWidth(state);
  };
  useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = async () => {
    await getPendingDocuments(1);
    await getCompletedDocuments(1);
    await getFolders(1);
    await getUncompletedDocuments(1);
    setLoading({
      completed: false,
      pending: false,
      uncompleted: false,
      folder: false,
    });
  };
  const getPendingDocuments = async (page, shouldSetLoading = false) => {
    setLoading({ ...loading, pending: true });
    try {
      const { data } = await axios.get(
        BASE_URL + "get-pending-documents?page=" + page,
        getHeaders()
      );

      setPendingDocuments({
        ...pendingDocuments,
        pages: data.pages,
        data: [...data.data],
        loading: false,
      });
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        { variant: "error" }
      );
    }
    if (shouldSetLoading) {
      setLoading({ ...loading, pending: false });
    }
  };

  const getUncompletedDocuments = async (page, shouldSetLoading = false) => {
    setLoading({ ...loading, uncompleted: true });
    try {
      const { data } = await axios.get(
        BASE_URL + "getUncompletedDocuments?page=" + page,
        getHeaders()
      );

      setUnCompletedDocuments({
        ...uncompletedDocuments,
        pages: data.pages,
        data: [...data.data],
        loading: false,
      });
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        { variant: "error" }
      );
    }
    if (shouldSetLoading) {
      setLoading({ ...loading, uncompleted: false });
    }
  };
  const getCompletedDocuments = async (page, shouldSetLoading = false) => {
    setLoading({ ...loading, completed: true });
    try {
      const { data } = await axios.get(
        BASE_URL + "get-completed-documents?page=" + page,
        getHeaders()
      );

      setCompletedDocuments({
        ...completedDocuments,
        pages: data.pages,
        data: [...data.data],
        loading: false,
      });
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        { variant: "error" }
      );
    }
    if (shouldSetLoading) {
      setLoading({ ...loading, completed: false });
    }
  };

  const getFolders = async (page, shouldSetLoading = false) => {
    setLoading({ ...loading, folder: true });
    try {
      const { data } = await axios.get(
        BASE_URL + "getEsignFolders?page=" + page,
        getHeaders()
      );

      setFolders({
        ...folders,
        pages: data.pages,
        data: [...data.data],
        loading: false,
      });
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        { variant: "error" }
      );
    }
    if (shouldSetLoading) {
      setLoading({ ...loading, folder: false });
    }
  };

  const onCheckClick = (_id) => {
    if (fileToMoveId.indexOf(_id) > -1) {
      setFileToMoveId((prev) => prev.filter((id) => id !== _id));
    } else {
      setFileToMoveId([...fileToMoveId, _id]);
    }
  };

  const onMoved = () => {
    getPendingDocuments(1, true);
    getCompletedDocuments(1, true);
    getUncompletedDocuments(1, true);
    setFileToMoveId((prev) => []);
    setShowMoveButton(false);
  };
  const getFilesByFolderId = async (_id) => {
    setFolderFilesGetLoading(true);
    try {
      const { data } = await axios.get(
        BASE_URL + "get-files-by-folder-id?folderId=" + _id,
        getHeaders()
      );
      console.log("folder files", data);
      setFolderFiles(data.data);
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        { variant: "error" }
      );
    }

    setFolderFilesGetLoading(false);
  };
  const deleteDocument = async (id, documentType) => {
    var data = await removeEsignDOcuments(id);
    if (data) {
      Swal.fire(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      )
      if (documentType === "completed") {
        getCompletedDocuments(1, true);
      }
      if (documentType === "pending") {
        getPendingDocuments(1, true);
      }
      if (documentType === "uncompleted") getUncompletedDocuments(1, true);
      return;
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        footer: '<a href="">Why do I have this issue?</a>'
      })
    }
  };
  const deleteModal = async (id, documentType) => {
    Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      deleteDocument(id, documentType)
    }
  })}
  const onMenuItemClick = async (type, id, documentType, _originalFilename) => {
    if (type === "move") {
      setShowMoveButton(true);
      return;
    }
    if (type === "delete") {
      deleteModal(id, documentType);
    }
    if (type === "info") {
      setShowInfoModel(id);

      return;
    }
    if (type === "rename") {
      setOriginalFilname(_originalFilename);
      setShowRenameModal(id);
    }
  };
  const onRenamed = (created) => {
    setCompletedDocuments((prev) => ({
      ...prev,
      data: prev.data.map((item) =>
        item._id === showRenameModal
          ? { ...item, originalFilename: created.originalFilename }
          : item
      ),
    }));
    setPendingDocuments((prev) => ({
      ...prev,
      data: prev.data.map((item) =>
        item._id === showRenameModal
          ? { ...item, originalFilename: created.originalFilename }
          : item
      ),
    }));
    setUnCompletedDocuments((prev) => ({
      ...prev,
      data: prev.data.map((item) =>
        item._id === showRenameModal
          ? { ...item, originalFilename: created.originalFilename }
          : item
      ),
    }));
  };

  const handleFolderDeleteAfter = async (_id) => {
    setLoading({ ...loading, folder: true });
    try {
      const { data } = await axios.delete(
        BASE_URL + "delete_esign_folder?folderId=" + _id,
        getHeaders()
      );
      console.log("folder files", data);
      if(data.status === "success"){
        Swal.fire(
          'Deleted!',
          'Your Folder has been deleted.',
          'success'
        )
      }
      getFolders(1, false);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    }
    setLoading({ ...loading, folder: false });
  };
  const handleFolderDelete = async (_id, name) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        handleFolderDeleteAfter(_id)
      }
    })
  }
  const createFolderAPIRequest = async (foldername) => {
    const { data }= await axios.post(BASE_URL + "createEsignFolders/", { foldername }, getHeaders())
    return data;
  }
  useEffect(() => {
    if(showCreateFolderModal){
      folderCreateModalSwal(createFolderAPIRequest, setShowClearFolderModal, setFolders, folders);
    }
  },[showCreateFolderModal])
  console.log("fmwelfmewf", fileToMoveId, folders);
  const renameFile = async (id, fileName) => {
    try {
      const { data } = await axios.post(
        BASE_URL + "rename-esign-file/",
        { id, fileName },
        getHeaders()
      );
      Swal.fire(
        'Document Renamed',
        ' ',
        'success'
      )
      onRenamed(data.data);
      setShowRenameModal(false);
    } catch (error) {
      Swal.fire(
        'Error Renaming Document',
        ' ',
        'error'
      )
    }
    setLoading(false);
  };
  const renameFileHandler = async () => {
    const { value: formValues } = await Swal.fire({
      title: 'Create Folder',
      confirmButtonColor:'rgb(20, 155, 227)',
      html:
        `<input id="swal-input1" class="swal2-input" value=${originamFilename}>`,
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById('swal-input1').value,
        ]
      }
    })
    if (formValues && formValues[0] !== "") {
      renameFile(showRenameModal, formValues[0]);
    }

  }
  useEffect(() => {
    if(showRenameModal){
      renameFileHandler();
    }
  }, [showRenameModal])
  const moveFiles = async (fileToMoveId, folderId ) => {
    try {
      const data = await axios.post(
        BASE_URL + "moveEsigFilesIntoFolder/",
        { fileToMoveId, folderId },
        getHeaders()
      );
      Swal.fire(
        'Files Moved',
        ' ',
        'success'
      )
      onMoved();
    } catch (error) {
      Swal.fire(
        'Error Moving Files',
        ' ',
        'error'
      )
    }
  };
  const fileCreateModalSwal = async () =>{
    const { value: formValues } = await Swal.fire({
      title: 'Move File',
      confirmButtonColor:'rgb(20, 155, 227)',
      html:
        `<select id="swal-input2" class="swal2-input" >
            <option value="" disabled selected hidden>Folder Name</option>
        ${folders.data?.length > 0
          ? folders.data?.map((dir) => (
              `<option value=${dir._id}>
                ${dir.foldername}
              </option>`
            ))
          : null}
        </select>`,
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById('swal-input2').value
        ]
      }
    })
    if (formValues && formValues[0] !== "" ) {
      moveFiles(fileToMoveId, formValues[0])
    }
    setShowMoveInFolderModal(false);
  }
  useEffect(() =>{
    if(showMoveInFolderModal){
      fileCreateModalSwal();
    }
  }, [showMoveInFolderModal])
  return (
    <>
      <div className="nk-body npc-apps apps-only has-apps-sidebar npc-apps-files">
        <div className="nk-app-root">
          <div className="nk-main ">
            {/* wrap @s */}
            <div className="nk-wrap ">
              {/* main header @s */}
              <Navbar />
              {/* main header @e */}

              <div className="nk-content p-0">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div
                      className="nk-fmg"
                      style={bodyWidth ? { paddingLeft: 80 } : null}
                    >
                      <Sidebar page="shared" getState={sidebarState} />

                      <div className="nk-fmg-body">
                        <div className="nk-fmg-body-content">
                          <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between position-relative">
                              <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">
                                  eSignature
                                </h3>
                              </div>
                              <div className="nk-block-head-content">
                                <div
                                  className="nk-fmg-actions"
                                  style={{ width: 370 }}
                                >
                                  <ul
                                      className="nk-block-tools g-3"
                                      style={{ justifyContent: "flex-end" }}
                                    >
                                    <li>
                                      <div className="dropdown">
                                        <a
                                          onClick={() =>
                                            setShowClearFolderModal(true)
                                          }
                                          className="btn btn-light"
                                          data-toggle="dropdown"
                                          style={{ backgroundColor: 'rgb(20, 155, 227)', color: "white"}}
                                        >
                                          <em className="icon ni ni-plus" />{" "}
                                          <span>Create Folder</span>
                                        </a>

                                        

                                        {fileToMoveId.length > 0 &&
                                          folders.data.length > 0 && (
                                            <a
                                              style={{
                                                background: " rgb(20, 155, 227)",
                                                color: "white",
                                                marginLeft: "1rem",
                                              }}
                                              onClick={() =>
                                                setShowMoveInFolderModal(true)
                                              }
                                              className="btn btn-light"
                                              data-toggle="dropdown"
                                            >
                                              <span>Move</span>
                                            </a>
                                          )}
                                      </div>
                                    </li>
                                    <li>
                                      <EsigFileUpload folder={folder} />
                                    </li>
                                    <StorageType requestType={"esign"} />
                                  </ul>
                                </div>
                              </div>
                              <div
                                className="search-wrap px-2 d-lg-none"
                                data-search="search"
                              >
                                <div className="search-content">
                                  <a
                                    href="#"
                                    className="search-back btn btn-icon toggle-search"
                                    data-target="search"
                                  >
                                    <em className="icon ni ni-arrow-left" />
                                  </a>
                                  <input
                                    type="text"
                                    className="form-control border-transparent form-focus-none"
                                    placeholder="Search by user or message"
                                  />
                                  <button className="search-submit btn btn-icon">
                                    <em className="icon ni ni-search" />
                                  </button>
                                </div>
                              </div>
                              {/* .search-wrap */}
                            </div>
                          </div>

                          <div className="nk-fmg-listing nk-block">
                            <div
                              className="toggle-expand-content expanded"
                              data-content="recent-files"
                            >
                              <div className="nk-files nk-files-view-group">
                                <div className="nk-files-group">
                                  <h6 className="title">
                                    SENT DOCUMENTS Folders
                                  </h6>
                                  <div className="nk-files-list">
                                    {loading.folder ? (
                                      <div className={classes.flex}>
                                        <CircularProgress  style={{color:"rgb(20, 155, 227)"}}/>
                                      </div>
                                    ) : folders.data.length > 0 ? (
                                      folders.data.map((document, index) => (
                                        <FolderList
                                          onDeleteClick={() =>
                                            handleFolderDelete(document._id)
                                          }
                                          onClick={() => {
                                            localStorage.setItem(
                                              "id",
                                              document._id
                                            );
                                            localStorage.setItem(
                                              "foldername",
                                              document.foldername
                                            );

                                            history.push("/esign-folder-files");
                                          }}
                                          foldername={document.foldername}
                                        />
                                      ))
                                    ) : (
                                      <p
                                        style={{
                                          marginLeft: "9px",
                                          textAlign: "center",
                                          width: "100%",
                                        }}
                                      >
                                        Folder you create will show up here
                                      </p>
                                    )}
                                  </div>
                                  <Paginator
                                    pageCount={folders.pages}
                                    onChange={(page) => getFolders(page, true)}
                                  />
                                </div>

                                <div className="nk-files-group">
                                  <h6 className="title">Completed Documents</h6>
                                  <div className="nk-files-list">
                                    {loading.completed ? (
                                      <div className={classes.flex}>
                                        <CircularProgress  style={{color:"rgb(20, 155, 227)"}}/>
                                      </div>
                                    ) : completedDocuments?.data.length > 0 ? (
                                      completedDocuments?.data.map(
                                        (document, index) => (
                                          <FolderButton
                                            showCheckMark={showMoveButton}
                                            _id={document._id}
                                            onMenuItemClick={(type) =>
                                              onMenuItemClick(
                                                type,
                                                document._id,
                                                "completed",
                                                document.originalFilename
                                              )
                                            }
                                            fileToMoveId={fileToMoveId}
                                            onCheckClick={() =>
                                              onCheckClick(document._id)
                                            }
                                            onClick={() => {
                                              console.log("clickedddddd");
                                              localStorage.setItem(
                                                "pdfviewerFileName",
                                                document.filename
                                              );
                                              localStorage.setItem(
                                                "pdfviewerFileUrl",
                                                document.fileUrl
                                              );
                                              localStorage.setItem(
                                                "pdfViewerNodes",
                                                document.nodes
                                              );
                                              history.push(
                                                "/view-signed-documents"
                                              );
                                            }}
                                            originalFilename={
                                              document.originalFilename
                                            }
                                            updated_at={document.updated_at}
                                          />
                                        )
                                      )
                                    ) : (
                                      <p
                                        style={{
                                          marginLeft: "9px",
                                          textAlign: "center",
                                          width: "100%",
                                        }}
                                      >
                                        Documents that have been signed will
                                        show up here.
                                      </p>
                                    )}
                                  </div>
                                  <Paginator
                                    pageCount={completedDocuments.pages}
                                    onChange={(page) =>
                                      getCompletedDocuments(page, true)
                                    }
                                  />
                                </div>

                                <div className="nk-files-group">
                                  <h6 className="title">Pending Documents</h6>
                                  <div className="nk-files-list">
                                    {loading.pending ? (
                                      <div className={classes.flex}>
                                        <CircularProgress  style={{color:"rgb(20, 155, 227)"}}/>
                                      </div>
                                    ) : pendingDocuments?.data.length > 0 ? (
                                      pendingDocuments?.data.map(
                                        (document, index) => (
                                          <FolderButton
                                            showCheckMark={showMoveButton}
                                            _id={document._id}
                                            onMenuItemClick={(type) =>
                                              onMenuItemClick(
                                                type,
                                                document._id,
                                                "pending",
                                                document.originalFilename
                                              )
                                            }
                                            fileToMoveId={fileToMoveId}
                                            onCheckClick={() =>
                                              onCheckClick(document._id)
                                            }
                                            onClick={() => {
                                              history.push("/esign-pdf", {
                                                fileName: document.filename,
                                                fileUrl:document.fileUrl
                                              });
                                            }}
                                            originalFilename={
                                              document.originalFilename
                                            }
                                            updated_at={document.updated_at}
                                          />
                                        )
                                      )
                                    ) : (
                                      <p
                                        style={{
                                          marginLeft: "9px",
                                          textAlign: "center",
                                          width: "100%",
                                        }}
                                      >
                                        Documents that are pending signature
                                        show up here.
                                      </p>
                                    )}
                                  </div>
                                  <Paginator
                                    pageCount={pendingDocuments.pages}
                                    onChange={(page) =>
                                      getPendingDocuments(page, true)
                                    }
                                  />
                                </div>
                                <div className="nk-files-group">
                                  <h6 className="title">
                                    UnCompleted Documents
                                  </h6>
                                  <div className="nk-files-list">
                                    {loading.uncompleted ? (
                                      <div className={classes.flex}>
                                        <CircularProgress  style={{color:"rgb(20, 155, 227)"}}/>
                                      </div>
                                    ) : uncompletedDocuments.data.length > 0 ? (
                                      uncompletedDocuments.data.map(
                                        (document, index) => (
                                          <FolderButton
                                            showMoveButton={showMoveButton}
                                            onMenuItemClick={(type) =>
                                              onMenuItemClick(
                                                type,
                                                document._id,
                                                "uncompleted",
                                                document.originalFilename
                                              )
                                            }
                                            showCheckMark={showMoveButton}
                                            _id={document._id}
                                            fileToMoveId={fileToMoveId}
                                            onCheckClick={() =>
                                              onCheckClick(document._id)
                                            }
                                            onClick={() => {
                                              history.push("/esign-pdf", {
                                                fileName: document.filename,
                                                fileUrl:document.fileUrl
                                              });
                                            }}
                                            originalFilename={
                                              document.originalFilename
                                            }
                                            updated_at={document.updated_at}
                                          />
                                        )
                                      )
                                    ) : (
                                      <p
                                        style={{
                                          marginLeft: "9px",
                                          textAlign: "center",
                                          width: "100%",
                                        }}
                                      >
                                        Documents that have not been sent show
                                        up here.
                                      </p>
                                    )}
                                  </div>
                                  <Paginator
                                    pageCount={uncompletedDocuments.pages}
                                    onChange={(page) =>
                                      getUncompletedDocuments(page, true)
                                    }
                                  />
                                </div>
                              </div>
                              {/* .nk-files */}
                            </div>
                          </div>

                          {/* .nk-block */}
                        </div>
                        {/* .nk-fmg-body-content */}
                      </div>
                    </div>
                    {/* .nk-fmg */}
                  </div>
                </div>
              </div>
            </div>
            {/* content @e */}
          </div>
          {/* wrap @e */}

          {/* {showRenameModal && (
            <RenameFile
              existingName={originamFilename}
              id={showRenameModal}
              onCreated={(created) => onRenamed(created)}
              onClose={() => setShowRenameModal(false)}
            />
          )} */}
          {/* {showCreateFolderModal && (
            <CreateFolder
              onCreated={(created) =>{
                setFolders((prev) => ({
                  ...prev,
                  data: [...prev.data, created],
                }))
                setShowClearFolderModal(false)}
              }
              onClose={() => setShowClearFolderModal(false)}
            />
          )} */}
          {showInfoModel && (
            <DeviceAndLocationModal
              documentId={showInfoModel}
              onClose={() => setShowInfoModel(false)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Shared;
