import { Button, Fab, TextField, Tooltip } from "@material-ui/core";
import { AddCircle, Check, Delete, DeleteOutline, Save } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../constants/apiconstant";
import { getHeaders } from "../../utils";
import { useSnackbar } from "notistack";

export default function SideBar({
  isDisabled,
  activeNode = {},
  newParticipant,
  setNewParticipant,
  updateActiveNode,
  participants,
  updateAssignedUser,
  onDeleteClick,
  onAddFromContactClicked,
  fileName,
  setParticipants,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [showInputField, setShowInputField] = useState(false);
  const [emailValid, setEmailvalid] = useState(false);
  const [value, setValue] = useState("");
  const onAddClick = async () => {
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    var verified = emailPattern.test(value);
    const collab = {
      email: value,
      fileName: fileName,
      notify: false,
      permission: "signer",
    };
    if (verified) {
      try {
        collab.fileName = fileName;
        const { data } = await axios.post(
          BASE_URL + "createEsignatureParticipant/",
          {
            collab,
          },
          getHeaders()
        );
        console.log("aaaaaaaaaaaaaaaaaa", data);
        if (data.success) {
          const newData = {
            _id: data.Id,
            participantEmail: data.participantEmail,
            permission: data.permission,
          };
          setParticipants([...participants, newData]);
          setValue("");
          setShowInputField(false)

        }
      } catch (error) {
        if (error.response.status == 409) {
          enqueueSnackbar("User is already invited", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      }
    } else {
      setEmailvalid(true);
    }
  };

  return (
    <div
      style={{
        marginRight: "2rem",
        background: "white",
        height: "calc(100vh - 80px)",
        marginTop: "13px",
        marginBottom: "0px",
        minWidth: "20rem",
        borderRadius: "11px",
        overflowY: "auto",
      }}
    >
      <div style={{ padding: "1rem" }}>
        {isDisabled && (
          <div style={{ marginBottom: "2rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <p
                onClick={onDeleteClick}
                style={{
                  background: "red",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              >
                <DeleteOutline style={{ color: "white" }} />
              </p>{" "}
            </div>

            <h6
              style={{
                marginBottom: "0",
                paddingLeft: "1rem",
                fontFamily: "Nunito",
              }}
            >
              Field Name
            </h6>

            <input
              style={{
                marginTop: "1rem",
                marginLeft: "1rem",
                border: "1px solid #ccc",
                width: "90%",
                fontFamily: "Nunito",
              }}
              type="text"
              className="form-control border-transparent form-focus-none"
              placeholder="Enter name of the field"
              value={activeNode?.fieldAlias || ""}
              onChange={(e) => updateActiveNode({ fieldAlias: e.target.value })}
            />
          </div>
        )}
        <div style={{ paddingLeft: "1rem", marginBottom: "2rem" }}>
          <h6 style={{ fontFamily: "Nunito" }}>Assignee</h6>
          <p style={{ fontFamily: "Nunito" }}> {activeNode?.assigned ? activeNode?.assigned : "Not Assigned"}</p>
        </div>

        <Button
          onClick={onAddFromContactClicked}
          variant="contained"
          style={{
             borderRadius: "11px",
             marginBottom: "2rem",
             width: "100%",
             backgroundColor: "rgb(20 155 227)",
             color:"white",
             fontWeight: "bold"
            }}
        >
          View Contacts
        </Button>
        <div
          style={{
            border: "1px solid #ddd",
            marginBottom: "2rem",
            borderRadius: "11px",
          }}
        >
          <div
            style={{
              marginBottom: "1rem",

              background: "#ddd",
              paddingLeft: "1rem",
              padding: "0.5rem",
              borderRadius: "11px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h6 style={{ fontFamily: "Nunito" }}> Available User</h6>
            <div style={{ display: "flex" }}>
              <p
                onClick={() => setShowInputField(!showInputField)}
                variant="contained"
                style={{
                  cursor: "pointer",
                  marginRight: "0rem",
                  background: "rgb(20 155 227)",
                  color: "white",
                  borderRadius: "50%",
                  fontFamily: "Nunito",
                  marginRight: "1rem",
                }}
              >
                <Tooltip title="Add User" arrow placement="top">
                  <AddCircle />
                </Tooltip>
              </p>
            </div>
          </div>

          {showInputField && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0.5rem",
                alignItems: "center",
              }}
            >
              <TextField
                style={{ fontFamily: "Nunito" }}
                value={value}
                error={emailValid}
                onChange={(e) => {setValue(e.target.value); setEmailvalid(false)}}
                placeholder="Enter email address"
              />
              <div
                onClick={() => {
                  // setNewParticipant((prev) => [...prev, { participantEmail: value }]);
                  // setValue("");
                  onAddClick();
                  // setShowInputField(false);
                }}
                style={{
                  background: "rgb(20 155 227)",
                  borderRadius: "150px",
                  padding: "0.5rem",
                  cursor: "pointer",
                }}
              >
                <Tooltip title="Save" arrow placement="bottom">
                  <Save color="white" style={{ color: "white" }} />
                </Tooltip>
              </div>
            </div>
          )}

          {participants.filter((item) => item.permission === "signer").length === 0 && newParticipant.length === 0 && (
            <p style={{ paddingLeft: "1rem" }}> No users found</p>
          )}
          {participants.map((participant) => {
           if(participant.permission === "signer"){
             return (
              <div
                onClick={() => {
                  if (isDisabled) updateAssignedUser(participant.participantEmail);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "0.5rem",
                }}
              >
                <p style={{ paddingLeft: "1rem", margin: 0 }}>{participant.participantEmail}</p>
                {activeNode?.assigned === participant.participantEmail && (
                  <Check color="purple" style={{ color: "blue", marginRight: "1rem" }} />
                )}
              </div>
            );
          }
          })}
          {newParticipant.map((participant) => {
            return (
              <div
                onClick={() => isDisabled && updateAssignedUser(participant.participantEmail)}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "0.5rem",
                }}
              >
                <p style={{ paddingLeft: "1rem", margin: 0 }}>{participant.participantEmail}</p>
                {activeNode?.assigned === participant.participantEmail && (
                  <Check color="purple" style={{ color: "blue", marginRight: "1rem" }} />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
