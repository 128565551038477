import { FormControl, FormGroup, FormControlLabel, Switch, styled, alpha } from "@material-ui/core";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../constants/apiconstant";
import { Loading } from "../pages/configuration/components/Loading";
import { getHeaders } from "../utils";

export default function StorageType({requestType}) {
  const [storageTypeLoading, setStorageTypeLoading] = useState(true);
  const [storageTypeAws, setStorageTypeAws] = useState(false);
  const [storageTypeAwsChangeLoading, setStorageTypeAwsChangedLoading] = useState(false);
  const [storageTypeStorjChangeLoading, setStorageTypeStorjChangedLoading] = useState(false);
  const [storageTypeStorj, setStorageTypeStorj] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#149BE3",
      '&:hover': {
        backgroundColor: alpha("#149BE3", theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#149BE3",
    },
  }));

  useEffect(() => {
    getStorageType();
  }, []);

  const getStorageType = async () => {
    setStorageTypeLoading(true);
    try {
      const { data } = await axios.get(BASE_URL + "getStorageType?requestType=" + requestType, getHeaders());
      setStorageTypeAws(data.aws_storage_enabled);
      setStorageTypeStorj(data.storj_storage_enabled);
    } catch (err) {
      enqueueSnackbar("An error occurred while getting storage type", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
    setStorageTypeLoading(false);
  };

  const storageTypeChanged = async (changeType) => {
    if (changeType == "aws") {
      if (storageTypeAws && !storageTypeStorj) {
        enqueueSnackbar("Cannot disable both storage type", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        return;
      }
    }

    if (changeType == "storj") {
      if (!storageTypeAws && storageTypeStorj) {
        enqueueSnackbar("Cannot disable both storage type", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        return;
      }
    }

    if (changeType == "aws") {
      setStorageTypeAwsChangedLoading(true);
    } else {
      setStorageTypeStorjChangedLoading(true);
    }

    try {
      const { data } = await axios.post(
        BASE_URL + "updateStorageType/",
        {
          requestType: requestType,
          storj_storage_enabled: changeType == "storj" ? !storageTypeStorj : storageTypeStorj,
          aws_storage_enabled: changeType == "aws" ? !storageTypeAws : storageTypeAws,
        },
        getHeaders()
      );
      setStorageTypeAws(data.aws_storage_enabled);
      setStorageTypeStorj(data.storj_storage_enabled);
    } catch (err) {
      enqueueSnackbar("An error occurred while updating storage type", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }

    if (changeType == "aws") {
      setStorageTypeAwsChangedLoading(false);
    } else {
      setStorageTypeStorjChangedLoading(false);
    }
  };

  return (
    <>
      {storageTypeLoading ? (
        <Loading style={{ height: "1rem" }} loaderSize={30} />
      ) : (
        <>
          <li>
            {storageTypeAwsChangeLoading ? (
              <Loading style={{ height: "1rem" }} loaderSize={30} />
            ) : (
              <FormGroup>
                <FormControlLabel
                  onChange={() => storageTypeChanged("aws")}
                  control={<CustomSwitch color="primary" checked={storageTypeAws} />}
                  label="AWS"
                />
              </FormGroup>
            )}
          </li>
          <li>
            {storageTypeStorjChangeLoading ? (
              <Loading style={{ height: "1rem" }} loaderSize={30} />
            ) : (
              <FormGroup>
                <FormControlLabel
                  onChange={() => storageTypeChanged("storj")}
                  control={<CustomSwitch color="primary" checked={storageTypeStorj} />}
                  label="Storj"
                />
              </FormGroup>
            )}
          </li>
        </>
      )}
    </>
  );
}
