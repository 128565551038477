import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getHeaders } from "../../utils";
import { Modal } from "../configuration/components/Modal";
import InitialsGenerator from "../configuration/components/InitialsGenerator";
import ImageUploader from "../configuration/components/imageUpload";
import { Button, CircularProgress, Fab, FormControlLabel } from "@material-ui/core";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import SendIcon from "@material-ui/icons/Send";
import { printPdf, renderPdf } from "../Shared/utils";
import SignaturePad from "signature_pad";
import Header from "./Header";
import Resizer from "react-image-file-resizer";
import { ArrowBack, CheckBox, Save, TramRounded } from "@material-ui/icons";
import { getRemainingFieldCount } from "./utils";
import LeftSidebarImage from "../Shared/LeftSidebarImage";
import { BASE_URL } from "../../constants/apiconstant";
import { Print } from "@material-ui/icons";
import { ShowAbsoluteButton } from "../Shared/EditPdf";
import useStateRef from "react-usestateref";
import { fontsArray } from "../Shared/constant";
import { v4 as uuidv4 } from "uuid";
import zoomIn from "../../assets/icons/zoom-in.png";
import zoomOut from "../../assets/icons/zoom-out.png";
import Swal from "sweetalert2";

var text1 = window.fabric.util.createClass(window.fabric.IText, {
  render: function (ctx) {
    this.clearContextTop();
    this.callSuper("render", ctx);
    this.cursorOffsetCache = {};
    this.renderCursorOrSelection();
    /*var bound = this.getBoundingRect();
            ctx.strokeStyle = this.fill;  
            ctx.strokeRect(
          bound.left + 0.5,
          bound.top + 0.5,
          bound.width,
          bound.height
            );*/
    ctx.strokeStyle = this.fill;
    let coords = this.calcCoords();
    ctx.beginPath();
    ctx.moveTo(coords.tl.x, coords.tl.y);
    ctx.lineTo(coords.tr.x, coords.tr.y);
    ctx.lineTo(coords.br.x, coords.br.y);
    ctx.lineTo(coords.bl.x, coords.bl.y);
    ctx.closePath();
    ctx.stroke();

    /*ctx.rect(this.left , this.top , this.width*this.scaleX , this.height*this.scaleY); 
            ctx.stroke();*/
  },
  /**
   * @private
   * @param {CanvasRenderingContext2D} ctx Context to render on
   */
  _render: function (ctx) {
    this.callSuper("_render", ctx);
  },
});
let addRectangleNew = (canvas, left, top, page) => {}
var permission = "viewer"
var signInitialTextRef = null;
export default function Index() {
  const [data, setData] = useState({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const nodesRef = useRef([]);
  const canvasArrayRef = useRef([]);
  const [canvasArray, setCanvasArray] = useState([]);
  const pdfRef = useRef(null);
  const [showSignPad, setShowSignPad] = useState(false);
  const [showInitialPad, setShowInitialPad] = useState(false);
  const activeNodeRef = useRef(null);
  const history = useHistory();
  const [activeNodes, setActiveNodes] = useState(false);
  const [consent, setConsent] = useState(false);
  const [isBold, setIsBold, isBoldRef] = useStateRef(false);
  const [isUnderLine, setIsUnderLine, isUnderlineRef] = useStateRef(false);
  const [isItalic, setIsItalic, isItalicRef] = useStateRef(false);
  const [fontSize, setFontSize, fontSizeRef] = useStateRef(16);
  const [fontFamily, setFontFamily, fontFamilyRef] = useStateRef(fontsArray[0]);
  const [isDocumentSaved, setIsDocumentSaved] = useState(false);
  const activeFieldRef = useRef(null);
  const [activeField, setActiveField] = useState(null);
  const activeFieldData = useRef(null);
  const fieldNodesRef = useRef([]);
  const disbaleClickRef = useRef(false);
  const [zoom, setZoom] = useState(1);
  const [zoomTop, setZoomTop] = useState("100px");
  const [Margin, setMargin] = useState("0px");

  const dynamicStylesForContainer = {
    display: "flex",
    paddingTop:permission==="viewer" ? "0px" : "13px",
    maxHeight: permission === "viewer" ? "100vh" : "calc(100vh - 54px)",
  };
  useEffect(() => {
    let margin = "";
    if (zoom >= 1.5 && zoom < 2.25) {
      margin = "11% 0 0 100px";
    } else if (zoom >= 2.25 && zoom < 3.375) {
      margin = "22% 0 0 20%";
    } else if (zoom >= 3.375 && zoom < 5.0625) {
      margin = "31% 0 0 42%";
    } else if (zoom >= 5.0625 && zoom < 7.59375) {
      margin = "35.2% 0 0 1150px";
    } else if (zoom >= 7.59375 && zoom < 11.3906) {
      margin = "28% 0 0 120rem";
    } else if (zoom >= 11.3906) {
      margin = "20.87% 0 0 194rem";
    }
    setMargin(margin);
  }, [zoom]);

  useEffect(() => {
    getDataFromToken();
  }, []);
  useEffect(() => {
    activeFieldData.current = activeField;
  }, [activeField]);
  useEffect(() => {
    permission = data.permission;
  },[data])
  const getDataFromToken = async () => {
    Swal.fire({
      title: 'Getting Document',
      timerProgressBar: true,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const token = new URLSearchParams(window.location.search).get("token");
      const { data } = await axios.get(BASE_URL + "decode-jwt?token=" + token, getHeaders());
      // await axios.get(BASE_URL + "saveUserDeviceAndLocationInfo?token=" + token, getHeaders());
      console.log("[getDataFromToken]-data", data);

      setConsent(data?.data?.consent || false);
      let nodes = []
      if (data.data.nodes.length>0){
        nodes = JSON.parse(data?.data?.nodes);
      }
      setData({ ...data.data, nodes: nodes});
      nodesRef.current =nodes.map((node) => {
        if (node.fieldType === "date") {
          return {
            ...node,
            text: node.text="" ? "Enter Date" : node.text,
            isComplete: true,
          };
        }
        return {
          ...node,
          text: node.text==="" ? "Enter Text" : node.text,
        };
      });
      nodes = nodesRef.current;

      const result = await renderPdf(data.data.fileUrl);
      canvasArrayRef.current = result.canvasArray;
      setCanvasArray(result.canvasArray);
      pdfRef.current = result.pdf;
      Swal.close()
      attachClickHandler(nodes, data.data.email);
    } catch (error) {
      console.log("errr", error);
      if(error?.response?.data){
        Swal.fire({
        icon: 'error',
        title:error.response?.data?.message,
        text:error.response?.data?.detail,
      })
    }
    }
  };
  const getCanvasObjectFromPage = (pageNumber) => {
    let canvasToReturn = null;

    canvasArrayRef.current.forEach(({ page, canvas }) => {
      console.log("age", page);
      if (page === pageNumber) {
        canvasToReturn = canvas;
      }
    });
    return canvasToReturn;
  };
    addRectangleNew = (canvas, left, top, page) => {
    setActiveNodes(false);
    disbaleClickRef.current = false;

    const fieldId = uuidv4();

    const textToShow =
      activeFieldRef.current === "sign"
        ? "Sign here"
        : activeFieldRef.current == "text" || activeFieldRef.current == "AText"
        ? ""
        : activeFieldRef.current === "text-field"
        ? ""
        : activeFieldRef.current === "initials"
        ? "Initial"
        : "Date";

    let textProperties = {
      left: left,
      top: top,
      width: 200, // Set an initial width value
      height: 200,
      minHeight: 400,
      fontSize: fontSizeRef.current,
      borderStyle: "solid",
      borderColor: "black", // Fix the borderColor value
      fieldId: fieldId,
      fieldAlias:
        activeFieldRef.current === "sign"
          ? "Sign Box"
          : activeFieldRef.current === "initials"
          ? "Initial Field"
          : activeFieldRef.current === "text"
          ? "Text Box"
          : activeFieldRef.current === "AText"
          ? "Enter Text"
          : activeFieldRef.current === "text-field"
          ? "Enter Text"
          : "Date Box",
      fieldType: activeFieldRef.current,
      assigned: null,
      public: activeFieldRef.current === "AText" ? true : false,
      required: false,
      text: textToShow,
      page,
      fontFamily: fontFamilyRef.current,
      underline: isUnderlineRef.current,
    };

    if (isBoldRef.current) {
      textProperties.fontWeight = "bold";
    }
    if (isItalicRef.current) {
      textProperties.fontStyle = "italic";
    }

    const text = new text1(textToShow, textProperties);
    if (activeFieldRef.current === "text-field") {
      text.width = 200;
      text.height = 40;
    }

    if (activeFieldRef.current === "initials") {
      text.width = 40;
      text.height = 20;
    }
    const textBoundingBox = text.getBoundingRect();

    const fieldAliasContent = textProperties.fieldAlias;
    const fieldAliasFontSize = 16;
    const fieldAliasFontFamily = "Arial";

    const tempCanvas = document.createElement("canvas");
    const tempCtx = tempCanvas.getContext("2d");
    tempCtx.font = `${fieldAliasFontSize}px ${fieldAliasFontFamily}`;
    const fieldAliasWidth = tempCtx.measureText(fieldAliasContent).width;

    // Calculate the width as the maximum between text content width, fieldAlias width, and minimum width
    const minWidth = 100;
    const maxWidth = Math.max(textBoundingBox.width, fieldAliasWidth, minWidth);

    // Adjust the width property based on the calculated maxWidth
    text.set({
      width: activeFieldRef.current === "initials" ? 40 : maxWidth,
    });

    fieldNodesRef.current = [
      ...fieldNodesRef.current,
      {
        ...textProperties,
        width: activeFieldRef.current === "initials" ? 40 : maxWidth, // Update width to the calculated maxWidth
        height: textBoundingBox.height,
      },
    ];
    canvas.add(text);
  };
  const onSaveClicked = async () => {
    try {
      const { response } = await axios.post(
        BASE_URL + "updateEsignature/",
        {
          nodes:JSON.stringify([...fieldNodesRef.current, ...nodesRef.current]),
          fileName: data.fileName,
          isEditing: true,
          newParticipants: []
        },
        getHeaders()
      );
      Swal.fire({
        icon: 'Success',
        title:`Saved Successfully`,
        text:`File has been saved`,
      })
      setTimeout(() => {
        history.goBack();
      }, 2000);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title:`Something went wrong!`,
        text:`Please try again later`,
      })
      console.log("err", error);
    }
  };
  const attachClickHandler = (nodes, emailFor) => {
    canvasArrayRef.current.forEach(({ page, canvas }) => {
      canvas.on("text:changed", function (e) {
        console.log("text:changed", e.target, e);
        nodesRef.current = nodesRef.current.map((node) => {
          if (node.fieldId === e.target.fieldId) {
            node.text = e.target.text;
            node.width = e.target.width;
            node.height = e.target.height;
            node.isComplete = true;
            activeNodeRef.current = node;
            setActiveNodes((prev) => ({
              ...prev,
              ...node,
              text: e.target.text,
              width: e.target.width,
              height: e.target.height,
              isComplete: true,
            }));
          }
          return node;
        });
        fieldNodesRef.current = fieldNodesRef.current.map((node) => {
          if (node.fieldId === e.target.fieldId) {
            node.text = e.target.text;
            node.width = e.target.width;
            node.height = e.target.height;
            node.isComplete = true;
            activeNodeRef.current = node;
            setActiveNodes((prev) => ({
              ...prev,
              ...node,
              text: e.target.text,
              width: e.target.width,
              height: e.target.height,
              isComplete: true,
            }));
          }
          return node;
        });
      });
      canvas.on("mouse:up", function (e) {
        if (e.target) {
          console.log("target ound", e.target);
          if (e.target.assigned === emailFor) {
            nodes.forEach((n) => {
              if (n.fieldId === e.target.fieldId) {
                console.log("found field");
                activeNodeRef.current = n;
                setActiveNodes((prev) => ({
                  ...prev,
                  ...n,
                }));
              }
            });
            if (e.target.fieldType === "sign" && activeNodeRef.current) setShowSignPad(true);
            if (e.target.fieldType === "initials" && activeNodeRef.current) setShowInitialPad(true);
          }
        } else {
          if (permission === "contributor" && activeFieldData.current == "AText" ){
            addRectangleNew(canvas, e.pointer.x, e.pointer.y, page);
          }
          console.log("not on target");
        }
      });
      canvas.on("object:modified", function (options) {
        console.log("options", options.target);

        const left = options.target.left;
        const top = options.target.top;
        const _modifiedFieldNodesRef = fieldNodesRef.current.map((node) => {
          if (node.fieldId === options.target.fieldId) {
            setActiveNodes((prev) => ({
              ...prev,
              ...node,
              left,
              top,
              minHeight: 500,
            }));
            return {
              ...node,
              left,
              top,
              minHeight: 500,
            };
          }
          return node;
        });
        const _existingNodeModified = nodesRef.current.map((node) => {
          if (node.fieldId === options.target.fieldId) {
            setActiveNodes((prev) => ({
              ...prev,
              ...node,
              left,
              top,
              minHeight: 500,
            }));
            return {
              ...node,
              left,
              top,
              minHeight: 500,
            };
          }
          return node;
        });
        nodesRef.current = _existingNodeModified;
        console.log("modifiedl", _modifiedFieldNodesRef);
        fieldNodesRef.current = _modifiedFieldNodesRef;
      });
    });
    nodes.forEach((node) => {
      let canvas = getCanvasObjectFromPage(node.page);
      console.log("canvas", canvas);
      addRectangle(canvas, node, emailFor);
    });

    /* modifyPdf(0, x, y, width, height) */
  };
  console.log("Acy8", activeNodes);

  const addSign = (fileName) => {
    setShowSignPad(false);

    const activeNode = activeNodeRef.current;
    const canvas = getCanvasObjectFromPage(activeNode.page);
    const canvasObject = canvas.getObjects().filter((item) => {
      return item.fieldId === activeNode.fieldId;
    });
    canvas.remove(canvasObject[0]);
    /*   var imgElement = document.getElementById(imageId);
    var imgInstance = new fabric.Image(imgElement, {
      left: activeNode.left,
      top: activeNode.top,
      angle: 0,
      opacity: 1,
      width: activeNode.width,
      height: activeNode.width,
    });
    canvas.add(imgInstance);
 */

    window.fabric.Image.fromURL(
      fileName,
      function (myImg) {
        //i create an extra var for to change some image properties
        var img1 = myImg.set({
          left: activeNode.left,
          top: activeNode.top,
          width: activeNode.width,
          height: activeNode.height,
        });
        canvas.add(img1);
      },
      {
        crossOrigin: "Anonymous",
      }
    );

    /* 
        var img = document.createElement('img')

        img.id = imageId
        img.src = imageData
        img.style.width = activeNode.width + 'px'
        img.style.height = activeNode.height + 'px'
        img.style.position = 'absolute'
        img.style.top = activeNode.top + 'px'
        img.style.left = activeNode.left + 'px'
        console.log('Activbe', activeNode) */

    /*  document.getElementById('pagec' + activeNode.page).appendChild(img) */
    nodesRef.current = nodesRef.current.map((node) => {
      if (node.fieldId === activeNode.fieldId) {
        node.imageData = fileName;
        node.isComplete = true;
      }
      return node;
    });

    activeNodeRef.current = null;
  };

  const addInitialSignature = (fileName, x=false, width, height) => {
    setShowInitialPad(false);
    setShowSignPad(false);

    const activeNode = activeNodeRef.current;
    const canvas = getCanvasObjectFromPage(activeNode.page);
    const canvasObject = canvas.getObjects().filter((item) => {
      return item.fieldId === activeNode.fieldId;
    });
    canvas.remove(canvasObject[0]);
    /*   var imgElement = document.getElementById(imageId);
    var imgInstance = new fabric.Image(imgElement, {
      left: activeNode.left,
      top: activeNode.top,
      angle: 0,
      opacity: 1,
      width: activeNode.width,
      height: activeNode.width,
    });
    canvas.add(imgInstance);
 */

if(x){
var text =signInitialTextRef.text;
  console.log("object------------------------------------", signInitialTextRef)
  var textCanvas = document.createElement('canvas');
  var context = textCanvas.getContext('2d');

  textCanvas.width = activeNode.width;
  textCanvas.height = activeNode.height;

  context.font ="25px " + signInitialTextRef.font;
  context.fillStyle = 'black';
  context.fillText(text, 2, 20);

  var textImageURL = textCanvas.toDataURL();
  fileName = textImageURL;
  window.fabric.Image.fromURL(
    textImageURL,
    function (textImg) {
      var img1 = textImg.set({
        left: activeNode.left,
        top: activeNode.top,
        width: activeNode.width,
        height: activeNode.height,
      });
      canvas.add(img1);
    },
    {
      crossOrigin: "Anonymous",
    }
  );
} else {
  window.fabric.Image.fromURL(
      fileName,
      function (myImg) {
        //i create an extra var for to change some image properties
        var img1 = myImg.set({
          left: activeNode.left,
          top: activeNode.top,
          width: activeNode.width,
          height: activeNode.height,
        });
        canvas.add(img1);
      },
      {
        crossOrigin: "Anonymous",
      }
    );
  }

    /* 
        var img = document.createElement('img')

        img.id = imageId
        img.src = imageData
        img.style.width = activeNode.width + 'px'
        img.style.height = activeNode.height + 'px'
        img.style.position = 'absolute'
        img.style.top = activeNode.top + 'px'
        img.style.left = activeNode.left + 'px'
        console.log('Activbe', activeNode) */

    /*  document.getElementById('pagec' + activeNode.page).appendChild(img) */
    nodesRef.current = nodesRef.current.map((node) => {
      if (node.fieldId === activeNode.fieldId) {
        node.imageData = fileName;
        node.isComplete = true;
      }
      return node;
    });

    activeNodeRef.current = null;
  };

  const formatDate = () => {
    let d = new Date();
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return `${da}-${mo}-${ye}`;
  };

  useEffect(() => {
    updatePropertiesOnCanvas({ fontWeight: isBold ? "bold" : "normal" });
  }, [isBold]);

  useEffect(() => {
    updatePropertiesOnCanvas({ fontWeight: isItalic ? "italic" : "normal" });
  }, [isItalic]);

  useEffect(() => {
    updatePropertiesOnCanvas({ underline: isUnderLine });
  }, [isUnderLine]);

  useEffect(() => {
    updatePropertiesOnCanvas({ fontSize: fontSize });
  }, [fontSize]);

  useEffect(() => {
    updatePropertiesOnCanvas({ fontFamily: fontFamily });
  }, [fontFamily]);

  const updateActiveNode = (value) => {
    let _modifiedFieldNodesRef = nodesRef.current.map((node) => {
      if (node.fieldId === activeNodes.fieldId) {
        return {
          ...node,
          ...value,
        };
      }

      return node;
    });
    nodesRef.current = _modifiedFieldNodesRef;
    _modifiedFieldNodesRef = fieldNodesRef.current.map((node) => {
      if (node.fieldId === activeNodes.fieldId) {
        return {
          ...node,
          ...value,
        };
      }

      return node;
    });

    fieldNodesRef.current = _modifiedFieldNodesRef;
    setActiveNodes((prev) => ({ ...prev, ...value }));
  };

  const updatePropertiesOnCanvas = (property) => {
    if (activeNodes) {
      let canvas = getCanvasObjectFromPage(activeNodes.page);
      let activeObject = null;
      let objectsArray = canvas.getObjects().filter((item) => {
        //     console.log("item", item, node)
        return item.fieldId === activeNodes.fieldId;
      });

      if (objectsArray.length > 0) {
        activeObject = objectsArray[0];
        activeObject.set(property);
        canvas.renderAll();
        console.log("activeobje", {activeNodes}, activeObject, property);
        const width = activeObject.width * activeObject.scaleX;
        const height = activeObject.height * activeObject.scaleY;
        updateActiveNode({ ...property, width, height, minHeight: 1000 });
      }
    } else {
      console.log("no active node");
    }
  };

  const addRectangle = (canvas, node, emailFor) => {
    // Render the Textbox on Canvas

    console.log(
      "emailFor === node.assigned && node.type !== date",
      emailFor === node.assigned && node.fieldType !== "date"
    );
    let objToAdd = null;
    if (node.fieldType === "sign") {
      if (node.imageData) {
        window.fabric.Image.fromURL(
          node.imageData,
          function (myImg) {
            //i create an extra var for to change some image properties
            var img1 = myImg.set({
              left: node.left,
              top: node.top,
              width: node.width,
              height: node.height,
              selectable: false,
            });
            canvas.add(img1);
          },
          {
            crossOrigin: "Anonymous",
          }
        );
      } else {
        let text = node.text || "Sign Here";
        objToAdd = new text1(text, {
          ...node,

          hasBorders: false,
          hasControls: false,
          hasRotatingPoint: false,

          selectable: emailFor === node.assigned,
        });
      }
    }

    if (node.fieldType === "text") {
      let text = node.text || "Some text here";
      objToAdd = new text1(text, {
        ...node,

        hasBorders: (permission ==="contributor" && node.assigned === null) ? true: false,
        hasControls: (permission ==="contributor" && node.assigned === null) ? true: false,
        hasRotatingPoint: false,

        margin: 10,

        selectable: (permission ==="contributor" && node.assigned === null) ? true : emailFor === node.assigned,
      });
    }
    if (node.fieldType === "date") {
      console.log("in dateeeeeeeeeeeeeeeeeeeeee");
      let text = node.text;
      delete node.text;
      objToAdd = new text1(node.date ? node.date : emailFor === node.assigned ? formatDate() : text, {
        ...node,

        hasBorders: false,
        hasControls: false,

        hasRotatingPoint: false,

        selectable: emailFor === node.assigned && node.fieldType !== "date",
      });
    }

    if (node.fieldType === "text-field" || node.fieldType === "AText") {
      let text = node.text || "Some text here";
      objToAdd = new text1(text, {
        ...node,
        hasBorders:  (permission ==="contributor" && node.assigned === null) ? true: false,
        hasControls: (permission ==="contributor" && node.assigned === null) ? true: false,
        hasRotatingPoint: false,
        margin: 10,
        selectable: (permission ==="contributor" && node.assigned === null) ? true :( node.fieldType === "text-field" || node.fieldType === "AText") ? emailFor === node.assigned : "",
      });
    }

    if (node.fieldType === "initials") {
      if (node.imageData) {
        window.fabric.Image.fromURL(
          node.imageData,
          function (myImg) {
            var img1 = myImg.set({
              left: node.left,
              top: node.top,
              width: node.width,
              height: node.height,
              selectable: false,
            });
            canvas.add(img1);
          },
          {
            crossOrigin: "Anonymous",
          }
        );
      } else {
      let text = node.text || "Initial Here";
      objToAdd = new text1(text, {
        ...node,
        hasBorders: false,
        hasControls: false,
        hasRotatingPoint: false,
        margin: 10,
        selectable: emailFor === node.assigned,
      });
    }
    }

    if (objToAdd) {
      canvas.add(objToAdd);
    }
  };
  const onSaveClick = async () => {
    if (isDocumentSaved) {
      Swal.fire({
        icon: 'error',
        title:'Already Signed',
        text: `You have already signed this document!`,
      })
      return;
    }
    let { remainingFieldCount, remainingFieldArray } = getRemainingFieldCount(nodesRef.current, data.email);

    if (remainingFieldCount > 0) {
      let errorFieldString = "";
      remainingFieldArray.forEach((index, item) => {
        if (index == 0) errorFieldString = item;
        else {
          errorFieldString += "," + item;
        }
      });
      // enqueueSnackbar("Please complete the fields - " + remainingFieldArray, {
      //   variant: "error",
      //   anchorOrigin: {
      //     vertical: "bottom",
      //     horizontal: "right",
      //   },
      // });
      Swal.fire({
        icon: 'error',
        title:'Can\'t Send',
        text: `Please complete the required fields!`,
      })
    } else {
      try {
        Swal.fire({
          title: 'Finalizing Document',
          timerProgressBar: true,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        let isComplete = true;
        nodesRef.current.map((node) => {
          if (!node.isComplete) {
            isComplete = false;
          }
        });
        const nodes = nodesRef.current.map((node) => {
          if (node.fieldType === "date" && node.assigned === data.email && !node.date) {
            return {
              ...node,
              date: formatDate(),
            };
          }
          return node;
        });

        const response = await axios.post(BASE_URL + "updateEsignatureByParticipants/", {
          nodes: JSON.stringify(nodes),
          fileName: data.fileName,
          isComplete,
          email: data.email,
        });
        console.log("dataaaaa", response);
        Swal.close();
        if (response.data.success) {
          Swal.fire({
            icon: 'Success',
            title:`Document Finalized`,
            text:`Thanks for signing the document. An executed copy has been in your inbox.`,
          })
          setIsDocumentSaved(true);
        } else {
          Swal.fire({
            icon: 'error',
            title:`Error`,
            text:`${response.data.message}`,
          })
        }
      } catch (error) {
        console.log("errorrrr", error);
        Swal.fire({
          icon: 'error',
          title:`Error`,
          text:`Something went wrong!`,
        })
      }
    }
  };

  const onDeleteClick = () => {
    const _modifiedFieldNodesRef = [];
    fieldNodesRef.current.map((node) => {
      if (node.fieldId !== activeNodes.fieldId) {
        _modifiedFieldNodesRef.push(node);
      }
      const activeNode = activeNodeRef.current;
      const canvas = getCanvasObjectFromPage(activeNode.page);
      const canvasObject = canvas.getObjects().filter((item) => {
        return item.fieldId === activeNode.fieldId;
      });
      canvas.remove(canvasObject[0]);
    });
    fieldNodesRef.current = _modifiedFieldNodesRef;
    setActiveNodes(false);
    disbaleClickRef.current = false;
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "fixed",
          bottom: 20,
          right: 32,
          height: "auto",
          zIndex: 90999999,
        }}
      >
        {permission !== "contributor" && permission!=="viewer" && <Fab onClick={() => onSaveClick()} style={{ marginTop: 10, backgroundColor: "#000" }} color="primary">
          <SendIcon />
        </Fab>}
        <Fab
          onClick={() => printPdf(canvasArrayRef.current)}
          style={{ marginTop: 10, backgroundColor: "#000" }}
          color="primary"
        >
          <Print />
        </Fab>
        <Fab style={{ marginTop: 10, backgroundColor: "#000" }} color="primary" onClick={onSaveClicked}>
          <Save />
        </Fab>
      </div>
      <div id="abcdef">
        { data.permission !== "viewer" && <Header
          isItalic={isItalic}
          isBold={isBold}
          activeField={activeField}
          setActiveField={setActiveField}
          setZoomTop={setZoomTop}
          activeFieldRef={activeFieldRef}
          permission={permission}
          isUnderLine={isUnderLine}
          setIsBold={setIsBold}
          setIsItalic={setIsItalic}
          setIsUnderLine={setIsUnderLine}
          fontFamily={fontFamily}
          setFontFamily={setFontFamily}
          fontSize={fontSize}
          setFontSize={setFontSize}
          nodes={() => nodesRef.current}
          data={data}
        />}
        <div
          style={dynamicStylesForContainer}
        >
          <LeftSidebarImage canvasArray={canvasArray} permission={permission} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "33px",
              height: "max-content",
              alignItems: "center",
              position: "fixed",
              top: zoomTop,
              right: '70px',
              zIndex: 999,
              border: "1px solid black",
              borderRadius: "5px",
            }}
          >
            <div
              onClick={() => {
                zoom <= 11 ? setZoom(zoom + zoom / 2) : setZoom(zoom);
              }}
              className="zoom"
              style={{ cursor: "pointer", borderBottom: "1px solid black", width: "100%", textAlign: "center" }}
            >
              <img style={{ width: "75%", margin: "3px" }} className="imgZoom" src={zoomIn} />
            </div>
            <div
              onClick={() => {
                zoom >= 0.1 ? setZoom(zoom - zoom / 3) : setZoom(zoom);
              }}
              className="zoom"
              style={{ cursor: "pointer", width: "100%", textAlign: "center" }}
            >
              <img style={{ width: "75%", margin: "3px" }} className="imgZoom" src={zoomOut} />
            </div>
          </div>

          <div style={{ flex: 1, overflowY: "scroll", display: "flex" }}>
            <div style={{ display: "flex", paddingLeft: "4rem", width:"100%", justifyContent:"center" }}>
              <div id="pdf-viewer" style={{ transform: `scale(${zoom})`, margin: `${Margin}` }}></div>
            </div>
          </div>
        </div>
        <div id="sign-pad-image"></div>
        {showSignPad && (
          <SignatureInitialPad
          consent={consent}
          setConsent={setConsent}
          activeNode={activeNodeRef.current}
          onClose={() => setShowSignPad(false)}
          addSign={addInitialSignature}
          updatePropertiesOnCanvas={updatePropertiesOnCanvas}
        />
        )}
        {showInitialPad && (
          <SignatureInitialPad
            consent={consent}
            setConsent={setConsent}
            activeNode={activeNodeRef.current}
            onClose={() => setShowInitialPad(false)}
            addSign={addInitialSignature}
            updatePropertiesOnCanvas={updatePropertiesOnCanvas}
          />
        )}
        {activeNodes && <ShowAbsoluteButton activeNode={activeNodes} onDeleteClick={onDeleteClick} showDeleteIcon={permission==="contributor" ? true :false}/>}
      </div>
    </div>
  );
}


// For User Initial Generations
const SignatureInitialPad = ({ addSign, onClose, activeNode, consent, setConsent }) => {
  console.log("SignatureInitialPad", consent);
  const [isSignedInitial, setIsSignedInitial] = useState(false);
  const [initial, setInitial] = React.useState(null);
  const [file, setFile] = useState(null);
  const signPadRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const upload = useRef(null);
  useEffect(()=>{
    signInitialTextRef = {...initial};
  }, [initial])
  useEffect(() => {
    const interval = setInterval(() => {
      var parent = document.getElementById("initialpad_parent_div");
      const canvas = document.getElementById("initialpad_canvas");
      if (parent) {
        canvas.width = parent.offsetWidth;
        canvas.height = parent.offsetHeight;
        clearInterval(interval);
        var signaturePad = new SignaturePad(canvas);
        signPadRef.current = signaturePad;
      }
    }, 1000);
  }, []);

  const uploadImage = async(imageData)=>{
    const data = await axios.post(BASE_URL + "saveBase64/", {
      imgData: imageData,
      width: activeNode.width,
      height: activeNode.height,
    });
    console.log("kwelkflewkff", data);
    let imageId = "img" + new Date().valueOf();
    addSign(data.data.filename, '');
  }

  const onConfirm = async () => {
        console.log("initial Value", initial)
        if (!consent) {
      enqueueSnackbar("You must click the eSign consent button to proceed.", {
        variant: "error",
      });

      return;
    }
    if (!isSignedInitial && upload.current == null) {
      enqueueSnackbar("You have not added your initial yet.", {
        variant: "error",
      });
      return;
    }
    setLoading(true);
    try {
      if (initial) {
        addSign('', initial, true);
      } else {
        let data;
        let width, height;
        if (upload.current != null ){
          height = activeNode.height;
          width = activeNode.width;
          data = await axios.post(BASE_URL + "saveBase64/", {
            imgData:  upload.current,
            width: width,
            height: height,
          });
        } else {
          width = activeNode.width;
          height = activeNode.height;
          data = await axios.post(BASE_URL + "saveBase64/", {
          imgData:  signPadRef.current.toDataURL(),
          width: width,
          height: height,
        });}
        console.log("kwelkflewkff", data);
        let imageId = "img" + new Date().valueOf();
        addSign(data.data.filename, '', false, width, height);
      }
    } catch (error) {}
    setLoading(false);
    // var img = document.createElement("img");
    //
    //img.id = imageId;
    // img.src = signPadRef.current.toDataURL();
    // img.style.width = activeNode.width + "px";
    // img.style.height = activeNode.height + "px";
    //img.addEventListener("load", () => {

    // });

    //document.getElementById("test").appendChild(img);
  };
  return (
    <Modal
      showCancelButton={false}
      onClose={onClose}
      onConfirm={onConfirm}
      showConfirmButton={false}
      confirmButtonText="Sign"
      loading={loading}
      cancelButtonText="Close"
      modalTitle="Create Initials"
      modalSubTitle="Initials you create with eSign are legally valid."
      width={800}
      modalContent={
        <BasicTabs
          setIsSigned={setIsSignedInitial}
          consent={consent}
          onClose={onClose}
          loading={loading}
          onConfirm={onConfirm}
          setConsent={setConsent}
          initial={initial}
          upload={upload}
          setInitial={setInitial}
        />
      }
    />
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function BasicTabs(props) {
  const { setIsSigned, consent, setConsent, initial, setInitial, ...other } = props;
  const [value, setValue] = React.useState(0);
  const canvasRef = useRef(null);
  const canvasClickHandlerRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas) {
      const handleCanvasClick = () => {
        setIsSigned(true);
      };

      canvasClickHandlerRef.current = handleCanvasClick;

      canvas.addEventListener("click", canvasClickHandlerRef.current);

      return () => {
        canvas.removeEventListener("click", canvasClickHandlerRef.current);
      };
    }
  }, [setIsSigned, value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log("[BasicTabs]-initials", initial)
 const [modal, setModal ]= useState('draw')
  return (
    <Box sx={{ width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "space-evenly", margin: "10px 0 30px" }}>
        <div
          style={{
            display: "flex",
            padding: "5px",
            textAlign: "center",
            color: modal === "draw" ? "rgb(255, 255, 255)" : "rgb(20, 155, 227)",
            backgroundColor: modal === "draw" ? "rgb(20, 155, 227)" : "rgba(255,255,255)",
            border: "2px solid rgba(0,0,0,0.2)",
            borderRadius: "6px",
            cursor: "pointer",
          }}
          onClick={() => {
            setModal("draw");
          }}
        >
          <div style={{ margin: "0px 8px", width: "150px", fontSize: "16px", fontWeight: 600 }}>Draw</div>
        </div>
        <div
          style={{
            display: "flex",
            padding: "5px",
            textAlign: "center",
            color: modal === "upload" ? "rgb(255, 255, 255)" : "rgb(20, 155, 227)",
            backgroundColor: modal === "upload" ? "rgb(20, 155, 227)" : "rgba(255,255,255)",
            border: "2px solid rgba(0,0,0,0.2)",
            borderRadius: "6px",
            cursor: "pointer",
          }}
          onClick={() => {
            setModal("upload");
          }}
        >
          <div style={{ margin: "0px 8px", width: "150px", fontSize: "16px", fontWeight: 600 }}>Upload</div>
        </div>
        <div
          style={{
            display: "flex",
            padding: "5px",
            textAlign: "center",
            color: modal === "type" ? "rgb(255, 255, 255)" : "rgb(20, 155, 227)",
            backgroundColor: modal === "type" ? "rgb(20, 155, 227)" : "rgba(255,255,255)",
            border: "2px solid rgba(0,0,0,0.2)",
            borderRadius: "6px",
            cursor: "pointer",
          }}
          onClick={() => {
            setModal("type");
          }}
        >
          <div style={{ margin: "0px 8px", width: "150px", fontSize: "16px", fontWeight: 600 }}>Type</div>
        </div>
      </div>
      <div style={{ padding: "20px 40px" }}>
        <div style={{ display: modal === "draw" ? "block" : "none" }}>
          <div id="test"></div>
          <div id="initialpad_parent_div">
            <canvas ref={canvasRef} onClick={() => setIsSigned(true)} id="initialpad_canvas"></canvas>
          </div>
        </div>
        {modal == "upload" && <ImageUploader upload={props.upload} />}
        {modal == "type" && (
          <InitialsGenerator setSelectedInitial={setInitial} selectedInitial={initial} setIsSigned={setIsSigned} />
        )}
      </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                style={{ marginRight: "0.8rem" }}
                checked={consent}
                onChange={() => setConsent(!consent)}
                type="checkbox"
                name="consent"
                value="I consent to eSign"
              />
              <p style={{ fontSize: "1rem", display:"flex"}}>I consent to eSign<div style={{ margin:"0 0 0 6px" }}> <a href="https://ahrvo.com/data-privacy-policy/" style={{textDecoration:"underline", fontWeight:"600", fontSize:"13px"}}>Privacy and Data Protection Policy</a> and <a href="https://ahrvo.com/terms-of-service/" style={{textDecoration:"underline", fontWeight:"600", fontSize:"13px"}}>Terms of Services</a></div></p>
            </div>
            <div style={{ display: "flex", gap: "8px" }}>
              <Button onClick={other.onClose}  color="primary" style={{ color: "rgb(20, 155, 227)" }}>
                Close
              </Button>
              <Button
                onClick={other.onConfirm}
                variant="contained"
                style={{ backgroundColor: "rgb(20, 155, 227)", outline: "none", color: "white " }}
                autoFocus
              >
                {other.loading ? <CircularProgress color="#fff" size={20} /> : "Sign"}
              </Button>
            </div>
          </div>
    </Box>
  );
}
