// import './App.css'
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import EditPage from "./pages/EditPage";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Files from "./pages/Files";
import Foldersfiles from "./pages/Foldersfiles";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import EditPageDup from "./pages/EditPageDup";
import Dashboard from "./pages/Dashboard";
import Contact from "./pages/Contact";
import ContactDetail from "./pages/ContactDetail";
import UploadData from "./pages/UploadData";
import OcrDocument from "./pages/OcrDocument";

import EditOcrDocument from "./pages/EditOcrDocument";
import { Configuration } from "./pages/configuration/pages/Configuration";
import Shared from "./pages/Shared/Shared";
import EditPdf from "./pages/Shared/EditPdf";
import SignDocuments from "./pages/SignDocuments";
import ViewOnlyDocuments from "./pages/SignDocuments/ViewOnlyDocuments";
import EsignFolderFiles from "./pages/Shared/FolderFiles";
import NotificationPage from "./pages/NotificationPage";
import EsignDocInfo from "./pages/EsignDocInfo";
import OcrFolderFiles from "./pages/OcrFilesFolder";
import SignInFromRedirect from "./pages/SignInFromRedirect";
import AuthenticateFromEmail from "./pages/AuthenticateUserFromEmail";

import SharedDocuments from "./pages/sharedDocuments/SharedDocuments";
import UploadFiles from "./pages/sharedDocuments/UploadFiles";
import UploadProcess from "./pages/sharedDocuments/UploadProcess";
import SharedFoldersfiles from "./pages/sharedDocuments/SharedFoldersfiles";

//For User login and User Signup
import UserSignUp from "./pages/UserSignUp";
import UserSignIn from "./pages/UserSignIn";

import RedirectFromMainApp from "./pages/RedirectFromMainApp";
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "./constants/apiconstant";
import { CircularProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { getHeaders } from "./utils";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import TokenExpired from "./pages/TokenExpired";
import VerifyOtp from "./pages/VerifyOtp";
import SaveDeviceAndLocationInfoForFIleSharing from "./pages/sharedDocuments/saveDeviceAndLocation";
import ViewFile from "./pages/sharedDocuments/ViewFile";
import UserPrivateRoute from "./components/UserPrivateRoute";

const App = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const mainAppUrl = params.get("mainAppUrl");
    if (params.get("token")) {
      const redirectUrl = localStorage.getItem("redirectUrl");
      if (redirectUrl == null || redirectUrl === undefined) {
      localStorage.clear();
      }
    }
    if (mainAppUrl) {
      localStorage.setItem("mainAppUrl", mainAppUrl);
    }
  }, []);

  const history = useHistory();
  const location = useLocation();
  // const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  /*  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100%" }}>
        <CircularProgress />
      </div>
    );
  } */
  return (
    <>
      <PublicRoute path="/" component={SignIn} exact />
      <PublicRoute path="/signup" component={SignUp} />
      <PublicRoute path="/verify-otp" component={VerifyOtp} />
      <PublicRoute path="/user-login" component={UserSignIn} />
      <PublicRoute path="/user-signup" component={UserSignUp} />
      <PublicRoute path="/sign-up-from-redirect/:token" component={SignInFromRedirect} />
      <PublicRoute path="/redirectFromMainApp/:token/:user_id" component={RedirectFromMainApp} />

      <PublicRoute path="/token_expired/:token/:user_id" component={TokenExpired} />

      <PublicRoute path="/authenticate-user-from-email" component={AuthenticateFromEmail} />

      <PrivateRoute path={"/dashboard"} component={Dashboard} />
      <PrivateRoute path={"/notifications"} component={NotificationPage} />
      <PrivateRoute path={"/contact-details"} component={ContactDetail} />
      <PrivateRoute path={"/contact"} component={Contact} />
      <PrivateRoute path="/document-hub" component={HomePage} />
      <PrivateRoute path={"/eSign-documents"} component={Shared} />
      <PrivateRoute path={"/import"} component={UploadData} />
      <PrivateRoute path="/edit/:id?" component={EditPage} />
      <PrivateRoute path="/editdup/:id?" component={EditPageDup} />
      <PrivateRoute path="/created-documents" component={Files} />
      <PrivateRoute path="/foldersfiles/:id" component={Foldersfiles} />
      <PrivateRoute path="/document-ocr" component={OcrDocument} />
      <PrivateRoute path="/editocrdocument/:id?" component={EditOcrDocument} />
      <PrivateRoute path="/configuration" component={Configuration} />
      <PrivateRoute path="/esign-pdf" component={EditPdf} />
      <PrivateRoute path="/view-signed-documents" component={ViewOnlyDocuments} />
      <PrivateRoute path="/view-file" component={ViewFile} />
      <PrivateRoute path="/esign-document-device-info" component={EsignDocInfo} />
      <PrivateRoute path="/view-sign-document" component={ViewOnlyDocuments} />
      <PrivateRoute path="/esign-folder-files" component={EsignFolderFiles} />
      <PrivateRoute path="/ocr-folder-files" component={OcrFolderFiles} />
      <UserPrivateRoute path="/sign-document" component={SignDocuments} />
      <Route path="/save-device-location-document" component={SaveDeviceAndLocationInfoForFIleSharing} />
      <PrivateRoute path={"/file-sharing"} component={SharedDocuments} />
      <PrivateRoute path={"/upload-files"} component={UploadFiles} />
      <PrivateRoute path={"/upload-process-form"} component={UploadProcess} />
      <UserPrivateRoute path={"/sharedfoldersfiles/:id"} component={SharedFoldersfiles} />
    </>
  );
};

export default function WithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}
